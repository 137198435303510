<template>
  <header>
    <div class="container d-flex">
      <div class="logo"></div>
      <ul class="nav">
        <router-link tag="li"
                     to="/home">首页</router-link>
        <router-link tag="li"
                     to="/order">订单关怀</router-link>
        <router-link tag="li"
                     to="/marketing">精准营销</router-link>
        <router-link tag="li"
                     to="/analyze">数据分析</router-link>
        <router-link tag="li"
                     to="/contact-us">关于我们</router-link>
      </ul>
      <div></div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header'
}

</script>

<style lang="scss" scoped>
header {
  display: flex;
  width: 100%;
  height: 70px;
  background-color: #142b74;
  position: sticky;
  top: 0;
  z-index: 999;
  color: #fff;
}

.logo {
  width: 200px;
  background: url("~@/assets/images/logo.png") center no-repeat;
  background-size: 65%;
}

.nav {
  margin-left: 100px;

  li {
    float: left;
    height: 70px;
    line-height: 70px;
    font-size: 18px;
    cursor: pointer;

    & + li {
      margin-left: 20px;
    }
  }
}
</style>
