<template>
  <div>
    <Header />

    <BannerRegistration />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import BannerRegistration from '@/components/banner-registration'
export default {
  name: 'Culture',
  components: {
    Header,
    Footer,
    BannerRegistration
  }
}

</script>

<style lang="scss" scoped>
</style>
