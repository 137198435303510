<template>
  <div class="banner-registration">
    <div class="container">
      <span class="text">客多多期待与您合作</span>
      <button class="btn"
              @click="onClick">立即订购</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerRegistration',
  methods: {
    onClick () {
      window.open('https://fw.jd.com/main/detail/FW_GOODS-530013?p=fw&t=searchbutton')
    }
  }
}

</script>

<style lang="scss" scoped>
.banner-registration {
  position: relative;
  padding: 80px;
  background: url("~@/assets/images/banner03.jpg") no-repeat center;
  background-size: 100%;

  .text {
    font-size: 20px;
    color: #fff;
    margin-right: 20px;
  }

  .btn {
    width: 120px;
    padding: 6px;
    background: linear-gradient(
      to right,
      rgb(92, 96, 245),
      rgb(137, 87, 255),
      rgb(255, 187, 0)
    );
    color: #fff;
    border: none;
    border-radius: 20px;
  }
}
</style>
